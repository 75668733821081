import { gql, useQuery } from "@apollo/client";
import { useModals } from "ModalProvider";
import { parseError } from "apollo";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import Status from "components/Status";
import { NavigationBar } from "components/base";
import CharlesButton from "components/charles/base";
import moment from "moment";
import ReviewRequestView from "./ReviewRequestView";
import { FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS } from "./graphql";

const SupplierStockUpdateRequests = () => {
  const { loading, data, error } = useQuery(
    FETCH_SUPPLIER_PENDING_INVENTORY_UPDATE_REQUESTS,
    {
      fetchPolicy:
        process.env.NODE_ENV === "development" ? "cache-first" : "network-only",
    },
  );
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const requests = data.supplierStockUpdateRequests.results;

  function review(request) {
    modal.present({
      title: "Review",
      subtitle: `${request.supplier.name} ${moment(request.createdAt).format("YYYY-MM-DD hh:mm:ss")}`,
      maxWidth: "max-w-7xl",
      children: <ReviewRequestView id={request.id} hide={modal.hide} />,
    });
  }

  return (
    <div>
      <NavigationBar title="Supplier Stock Update Requests" />

      <div className="m-6 card p-4">
        <table>
          <thead>
            <tr>
              <th>Supplier</th>
              <th className="text-right">State</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((i) => (
              <tr
                key={i.id}
                className="border-b border-gray-100 dark:border-gray-700"
              >
                <td>
                  <CharlesButton onClick={() => review(i)}>
                    {i.supplier.name}{" "}
                    {moment(i.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                  </CharlesButton>
                </td>
                <td className="text-right">
                  <Status status={i.state} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierStockUpdateRequests;
