import { useQuery } from "@apollo/client";
import Calendar from "components/Calendar";
import Errors from "components/Errors";
import { Select } from "components/Form";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { useState } from "react";
import ProductionPlans from "./ProductionPlans";
import {
  FETCH_PRODUCTION_CALENDAR,
  FETCH_PRODUCTION_CALENDARS,
} from "./graphql";
import { parseError } from "apollo";
import { useSearchParams } from "react-router-dom";

const SupplierCalendar = () => {
  const [searchParams, setSearchParams] = useSearchParams({ id: "1" });

  const { loading, error, data } = useQuery(FETCH_PRODUCTION_CALENDARS);

  const today = new Date();
  const yearOptions = [...Array(3).keys()].map(
    (i) => today.getFullYear() - i + 1,
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const params = Object.fromEntries(searchParams.entries());

  console.log("params", params);

  return (
    <Page title="Production Calendar" className="overflow-auto">
      <div className="p-6 text-sm flex-1 overflow-auto">
        <div className="flex space-x-8">
          <div className="flex items-center space-x-2">
            <label htmlFor="">Production Category: </label>
            <Select
              value={params.id}
              onChange={(e) =>
                setSearchParams({ ...params, id: e.target.value })
              }
            >
              {data.productionCalendars.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
            </Select>
          </div>
        </div>

        <div className="mt-6 relative flex-1 overflow-auto">
          <Content id={params.id} />
        </div>
      </div>
    </Page>
  );
};

const Content = ({ id }) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_CALENDAR, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  const [year, setYear] = useState(new Date().getFullYear());

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const usedDates = data.productionCalendar.usages
    .filter((i) => i.date)
    .map((i) => i.date);

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className=" col-span-9">
        <Calendar year={year} setYear={setYear} selectedDates={usedDates} />
        <div className="mt-4 opacity-70 italic">
          The blue dates are the production dates.
        </div>
      </div>

      <div className="col-span-3">
        <div className="card px-8 py-6">
          <h5>Plans</h5>
          <div className="mt-2">
            <ProductionPlans productionCalendarId={id} year={year} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierCalendar;
