import { useQuery } from "@apollo/client";
import XLSX from "xlsx";
import { parseError } from "apollo";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { useState } from "react";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { searchByProp } from "utils/search";
import ProductStock from "./ProductStock";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import moment from "moment";
import { Select } from "components/Form";
import SupplierUpdateRequestsView from "./SupplierUpdateRequestsView";
import SupplierStockUpdateRequests from "./SupplierStockUpdateRequests";
import { byName } from "utils/sort";
import { FETCH_SUPPLIER_STOCKS } from "./graphql";
import SupplierStockDeliveryForm from "./SupplierStockDeliveryForm";

const SupplierStocksIndex = () => {
  return (
    <Routes>
      <Route index element={<SupplierStocks />} />
      <Route path=":id" element={<ProductStock />} />
      <Route path="update-requests" element={<SupplierStockUpdateRequests />} />
    </Routes>
  );
};

const SupplierStocks = () => {
  const { loading, error, data } = useQuery(FETCH_SUPPLIER_STOCKS);
  const [searchParams, setSearchParams] = useSearchParams({
    filterSupplierId: "ALL",
  });
  const [searchText, setSearchText] = useState("");
  const modal = useModals();
  const navigate = useNavigate();

  if (loading) return <Spinner />;
  if (error) return <Errors error={parseError(error)} />;

  const filterSupplierId = searchParams.get("filterSupplierId") || "ALL";

  const supplierOptions = data.products.results
    .flatMap((i) => i.suppliers)
    .sort(byName)
    .filter((i, idx, arr) => arr.findIndex((j) => j.id === i.id) === idx);

  const products = data.products.results
    .filter((i) => {
      // Filter by supplier
      if (filterSupplierId !== "ALL") {
        return i.suppliers.some((j) => j.id === filterSupplierId);
      } else {
        return true;
      }
    })
    .filter((i) => searchByProp(i, ["name", "number"], searchText));
  const productsHasNoStock = products.filter(
    (i) => i.supplierStockForecast <= 0,
  );

  function exportStocks() {
    const data = [
      ...products.map((i) => ({
        Product: `[${i.number}] ${i.name}`,
        Supplier: i.suppliers.map((i) => i.name).join(","),
        "Planing QTY": i.supplierStockPlanningQty,
        "Forecast QTY": i.supplierStockForecast,
      })),
    ];
    const sheet = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "stock");
    const today = moment().format("YYYY-MM-DD");
    XLSX.writeFile(wb, `Supplier Stock until ${today}.xlsx`);
  }

  function showDeliveryForm() {
    modal.present({
      title: "Add Supplier Stock",
      children: (
        <SupplierStockDeliveryForm
          initData={{
            supplierId: filterSupplierId === "ALL" ? null : filterSupplierId,
          }}
          complete={(res) => {
            navigate(
              `/stock/supplier/${res.createSupplierStockRecord.supplierStockRecord.product.id}`,
            );
          }}
        />
      ),
    });
  }

  return (
    <div className="flex-1 overflow-auto flex flex-col p-6">
      <div className="p-6 card flex-1 overflow-auto flex flex-col">
        <div className="flex space-x-6 justify-between items-center">
          <Select
            value={filterSupplierId}
            onChange={(e) =>
              setSearchParams({ filterSupplierId: e.target.value })
            }
          >
            <option value="ALL">All Suppliers</option>
            {supplierOptions.map((i) => (
              <option key={i.id} value={i.id}>
                {i.name}
              </option>
            ))}
          </Select>

          <div className="flex-1">
            <SearchBar
              className="bg-gray-100"
              placeholder="Search by product name, number or supplier name."
              value={searchText}
              onChange={setSearchText}
            />
          </div>

          <CharlesButton onClick={exportStocks}>Export</CharlesButton>
          <CharlesButton onClick={showDeliveryForm}>
            + Add Supplier Stock
          </CharlesButton>
          <SupplierUpdateRequestsView />
        </div>

        <div className="opacity-70 mt-4 leading-relaxed">
          <p>
            This page shows our current supplier stocks. These data does not
            exist in Odoo. It is a 2nd level of stock we build by ourselves.
            This stock data will be included to compute a production plan in
            Order Scheduler.
          </p>
          <p>
            This page only shows the products if it is marked as `use supplier
            stock` in product.
          </p>
        </div>

        <div className="flex-1 overflow-auto relative my-2 -mx-2">
          <table>
            <thead>
              <tr className=" sticky top-0 z-10 bg-white">
                <th>Product</th>
                <th>Supplier</th>
                <th className="text-right">Planing QTY</th>
                <th className="text-right">Forecast QTY</th>
              </tr>
            </thead>
            <tbody>
              {products.map((i) => (
                <Link
                  to={`${i.id}?filterSupplierId=${filterSupplierId}`}
                  key={i.id}
                  className={`table-row font-normal border-b border-gray-100 dark:border-gray-700 hover:bg-blue-50 hover:dark:bg-blue-900 dark:hover:bg-opacity-20
                    ${i.supplierStockForecast === 0 ? "text-red-600 bg-red-50 dark:text-red-700 dark:bg-red-900 dark:hover:bg-red-800 dark:bg-opacity-20" : ""}
                  `}
                >
                  <td className="font-semibold">
                    [{i.number}] {i.name}
                  </td>
                  <td className="text-gray-800 dark:text-gray-100">
                    {i.suppliers.map((i) => i.name).join(",")}
                  </td>
                  <td className="text-right text-gray-800 dark:text-gray-100">
                    {i.supplierStockPlanningQty}
                  </td>
                  <td className="text-right text-gray-800 dark:text-gray-100">
                    {i.supplierStockForecast}
                  </td>
                </Link>
              ))}
            </tbody>
          </table>
        </div>

        <div className="italic mt-4">
          <span>Total {products.length} products. </span>
          {productsHasNoStock.length > 0 ? (
            <span className="text-red-600">
              {productsHasNoStock.length} products have no stock.
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SupplierStocksIndex;
