import { useMutation } from "@apollo/client";
import { CheckBox } from "components/base";
import CharlesButton from "components/charles/base";
import { Field } from "components/Form";
import { CubeSizeInput } from "components/ItemSizeInput";
import { useState } from "react";
import { BATCH_UPDATE_PRODUCTS } from "./graphql";
import { Alert } from "components/Toast";

const BatchUpdateForm = ({ hide, products }) => {
  const [ctnNetWeight, setCtnNetWeight] = useState("");
  const [ctnGrossWeight, setCtnGrossWeight] = useState("");
  const [outerCartonX, setOuterCartonX] = useState("");
  const [outerCartonY, setOuterCartonY] = useState("");
  const [outerCartonZ, setOuterCartonZ] = useState("");

  const [hasSelectedNetWeight, setHasSelectedNetWeight] = useState(false);
  const [hasSelectedGrossWeight, setHasSelectedGrossWeight] = useState(false);
  const [hasSelectedOuterCartonSize, setHasSelectedOuterCartonSize] =
    useState(false);

  const [batchUpdate, batchUpdateRes] = useMutation(BATCH_UPDATE_PRODUCTS, {
    onCompleted: () => {
      Alert("success", "Products batch updated successfully.");
    },
    onError: (err) => {
      Alert("error", parseError(err));
    },
  });

  const outerCartonCbm = () =>
    ((parseFloat(outerCartonX) + 0.5) *
      (parseFloat(outerCartonY) + 0.5) *
      (parseFloat(outerCartonZ) + 0.5)) /
    (100 * 100 * 100);

  const handleBatchUpdate = () => {
    // console.log(
    //   "batch update",
    //   outerCartonX,
    //   outerCartonY,
    //   outerCartonZ,
    //   ctnNetWeight,
    //   ctnGrossWeight,
    // );

    if (
      hasSelectedOuterCartonSize &&
      (outerCartonX === "" || outerCartonY === "" || outerCartonZ === "")
    ) {
      Alert("error", "Outer Carton Size is required.");
      return;
    }

    if (hasSelectedNetWeight && ctnNetWeight === "") {
      Alert("error", "CTN Net Weight is required.");
      return;
    }

    if (hasSelectedGrossWeight && ctnGrossWeight === "") {
      Alert("error", "CTN Gross Weight is required.");
      return;
    }

    batchUpdate({
      variables: {
        ids: products.map((p) => p.id),
        ctnNetWeight: hasSelectedNetWeight ? ctnNetWeight : null,
        ctnGrossWeight: hasSelectedGrossWeight ? ctnGrossWeight : null,
        outerCartonX: hasSelectedOuterCartonSize ? outerCartonX : null,
        outerCartonY: hasSelectedOuterCartonSize ? outerCartonY : null,
        outerCartonZ: hasSelectedOuterCartonSize ? outerCartonZ : null,
      },
    });

    hide();
  };

  return (
    <div className="space-y-4" data-testid="products-batch-update-form">
      <div className="space-y-2">
        <label className="block ">Selected Products: {products.length}</label>
        <div className="h-40 overflow-auto bg-white p-4 rounded-lg">
          {products.map((p) => (
            <div key={p.id} className="flex items-center space-x-4">
              <div className="w-40 whitespace-nowrap ">{p.number}</div>
              <div>{p.name}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-4">
        <label className="block ">Select options to update: </label>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedOuterCartonSize}
            onChange={() => {
              setHasSelectedOuterCartonSize(!hasSelectedOuterCartonSize);
            }}
          />

          <div
            className={`text-sm ${!hasSelectedOuterCartonSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <div className="flex items-center space-x-4">
              <label className="w-40">
                <div>Outer Carton Size:</div>
                <div className="text-xs text-gray-600">
                  Size 长(x) * 宽(z) * 高(y)
                </div>
              </label>

              <CubeSizeInput
                x={outerCartonX}
                y={outerCartonY}
                z={outerCartonZ}
                unit="cm"
                onChange={({ x, y, z }) => {
                  console.log("x, y, z", x, y, z);
                  setOuterCartonX(x);
                  setOuterCartonY(y);
                  setOuterCartonZ(z);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`text-sm ${!hasSelectedOuterCartonSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
        >
          <div className="flex items-center space-x-4 ml-10 text-xs">
            <label className="w-40"></label>
            <div>
              CBM: {outerCartonCbm() ? outerCartonCbm().toFixed(4) : "0"} m³
            </div>
            <div className=" text-gray-600">
              ((x + 0.5) * (y + 0.5) * (z + 0.5)) / (100 * 100 * 100)
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedNetWeight}
            onChange={() => {
              setHasSelectedNetWeight(!hasSelectedNetWeight);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedNetWeight ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <Field
              type="number"
              label="CTN Net Weight"
              value={ctnNetWeight}
              min={0}
              onChange={setCtnNetWeight}
              suffix="kg"
              labelWidth="w-40"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedGrossWeight}
            onChange={() => {
              setHasSelectedGrossWeight(!hasSelectedGrossWeight);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedGrossWeight ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <Field
              type="number"
              label="CTN Gross Weight"
              value={ctnGrossWeight}
              min={0}
              onChange={setCtnGrossWeight}
              suffix="kg"
              labelWidth="w-40"
            />
          </div>
        </div>
      </div>

      <CharlesButton
        onClick={handleBatchUpdate}
        disabled={
          hasSelectedGrossWeight ||
          hasSelectedNetWeight ||
          hasSelectedOuterCartonSize
            ? false
            : true
        }
      >
        Update
      </CharlesButton>
    </div>
  );
};

export default BatchUpdateForm;
