import { parseError } from "apollo";

const Errors = ({ error }) => {
  return (
    <div className="flex justify-center flex-col items-center z-30 text-pink-500 font-bold flex-1">
      <div>{parseError(error)}</div>
    </div>
  );
};

export default Errors;
