import { toast } from "react-toastify";

const options = {
  autoClose: 2500,
  hideProgressBar: true,
  closeButton: false,
  className:
    "rounded-2xl shadow-none text-white backdrop-blur-lg text-sm m-4 mb-4 lg:mx-0",
  bodyClassName: "px-4",
  draggable: false,
};

export function Alert(type, message, extraOptions = {}) {
  const finalOptions = { ...options, ...extraOptions };
  switch (type) {
    case toast.TYPE.SUCCESS:
      toast(message, {
        ...finalOptions,
        className: `${finalOptions.className} bg-green-500/80 `,
      });
      break;
    case toast.TYPE.ERROR:
      toast(message, {
        ...finalOptions,
        className: `${finalOptions.className} bg-red-500/80`,
      });
      break;
    default:
      break;
  }
}
