import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import Page from "components/Page";
import { Button } from "components/base";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import SearchBar from "components/SearchBar.js";
import { searchByProp } from "utils/search";
import { FETCH_ALL_USRS } from "../graphql";
import UserForm from "./UserForm";
import { useModals } from "ModalProvider";

const newUser = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  isActive: true,
};

function Accounts() {
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(FETCH_ALL_USRS);
  const modal = useModals();

  function showForm(user) {
    modal.present({
      title: "Create Account",
      center: true,
      children: <UserForm initialUser={user} onClose={modal.hide} />,
    });
  }

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page
      title="Accounts"
      rightButtons={<Button title="+ New Account" onClick={showForm} />}
    >
      <div className="flex-1 flex flex-col overflow-auto">
        <div className="p-4 lg:p-6">
          <SearchBar
            className="p-2 w-full"
            placeholder="Search customer by name or email"
            autoFocus
            type="text"
            value={searchText}
            onChange={(text) => setSearchText(text)}
          />
        </div>
        <div className="flex-1 rounded-lg overflow-auto m-4 mt-0 lg:m-6 lg:mt-0">
          <table className="bg-white dark:bg-gray-800">
            <thead className="sticky lg:top-0">
              <tr className="bg-gray-200 dark:bg-gray-700">
                <th className="text-grey-darker text-left px-4 lg:px-6 py-4">
                  User
                </th>
                <th className="text-left">Customers</th>
                <th className="text-grey-darker w-12 px-4 lg:px-6 py-4">
                  Active
                </th>
              </tr>
            </thead>
            <tbody>
              {data.allUsers
                .filter((user) =>
                  searchByProp(
                    user,
                    ["email", "firstName", "lastName"],
                    searchText,
                  ),
                )
                .map((user) => (
                  <tr key={user.id}>
                    <td className="py-2 lg:py-3 px-4 lg:px-6 border-b border-gray-100 dark:border-gray-700">
                      <Button
                        title={user.email}
                        onClick={() => showForm(user)}
                      ></Button>
                    </td>
                    <td className="border-b border-gray-100 dark:border-gray-700">
                      <div>
                        {user.customers.map((i) => (
                          <Button
                            key={i.id}
                            title={i.name}
                            link={`/customers/${i.id}`}
                          ></Button>
                        ))}
                      </div>
                    </td>
                    <td className="py-2 lg:py-3 px-4 lg:px-6 border-b border-gray-100 dark:border-gray-700">
                      <div className="flex justify-center">
                        <div
                          className={`w-4 ${user.isActive ? "text-green-500" : "text-red-500"}`}
                        >
                          {user.isActive ? <IoMdCheckmark /> : <IoMdClose />}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
}

export default Accounts;
