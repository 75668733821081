import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "App";
import { useMutation } from "@apollo/client";
import { Button } from "components/base";
import { Input, Switcher } from "components/Form";
import { Alert } from "components/Toast";
import { validateEmail } from "utils/string";
import { SAVE_USER, FETCH_ALL_USRS } from "../graphql";
import { useFetchMe } from "hooks/user";
import SetupMfaByCharles from "./SetupMfaByCharles";
import { nanoid } from "nanoid";
import { parseError } from "apollo";

function UserForm({ initialUser, onClose, onValuesChange }) {
  const [isValid, setIsValid] = useState(false);
  const [user, setUser] = useState(initialUser);
  const { data } = useFetchMe();
  const { hasPermission } = useContext(AppContext);

  const [saveUser, { loading }] = useMutation(SAVE_USER, {
    variables: {
      userInput: {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        isActive: user.isActive,
        password: user.password,
      },
    },
    onCompleted: (res) => {
      if (onValuesChange) {
        onValuesChange({
          id: res.saveUser.user.id,
          email: user.email,
          password: user.password,
        });
      }
      Alert("success", "User saved.");
      if (onClose) onClose();
    },
    refetchQueries: [{ query: FETCH_ALL_USRS }],
    onError: (error) => {
      Alert("error", parseError(error));
    },
  });

  useEffect(() => {
    if (user.email !== "") setIsValid(validateEmail(user.email));
  }, [user]);

  function randomPassword() {
    setUser((prev) => ({ ...prev, password: nanoid().slice(-16) }));
  }

  return (
    <div>
      <div>
        <div className="p-4 lg:p-6 bg-white dark:bg-gray-900 rounded-xl">
          <div className="flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Email:
            </label>
            <Input
              type="email"
              validated={user.email === "" || validateEmail(user.email)}
              className="ml-4 flex-1"
              value={user.email}
              onChange={(e) => {
                let email = e.target.value;
                setIsValid(validateEmail(email));
                setUser({ ...user, email });
              }}
            />
          </div>
          <div className="mt-4 flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              First Name:
            </label>
            <Input
              className="ml-4 flex-1"
              value={user.firstName}
              onChange={(e) => setUser({ ...user, firstName: e.target.value })}
            />
          </div>
          <div className="mt-4 flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Last Name:
            </label>
            <Input
              className="ml-4 flex-1"
              value={user.lastName}
              onChange={(e) => setUser({ ...user, lastName: e.target.value })}
            />
          </div>
        </div>
      </div>

      <div className="mt-6">
        <div className="px-4 py-2 lg:p-6 lg:py-4 bg-white dark:bg-gray-900 rounded-xl ">
          <div className="flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Password
            </label>
            <Input
              className="ml-4 flex-1"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <Button
              title="Generate"
              className="ml-4"
              onClick={randomPassword}
            />
          </div>
          {data && data.me && hasPermission() ? (
            <div>
              <SetupMfaByCharles email={user.email} />
            </div>
          ) : null}
        </div>
        <div className="text-sm text-gray-600 mt-2 mx-4 lg:mx-6">
          Our passwords are encrypted, we don't know anyone's password. We could
          just reset them. Leave it empty if you don't need to reset this user's
          password.
        </div>
      </div>

      <div className="mt-6">
        <div className="px-4 py-2 lg:p-6 lg:py-4 bg-white dark:bg-gray-900 rounded-xl ">
          <div className="flex items-center">
            <label htmlFor="" className="w-1/4 inline-block">
              Is Active:
            </label>
            <div className="flex justify-end flex-1 ml-4">
              <Switcher
                isOn={user.isActive}
                onChange={() => setUser({ ...user, isActive: !user.isActive })}
              />
            </div>
          </div>
        </div>
        <div className="text-sm text-gray-600 mt-2 mx-4 lg:mx-6">
          If account is not active, he/she could not login to biz and wis.
        </div>
      </div>

      <Button
        loading={loading}
        disabled={loading || !isValid}
        className="mt-8"
        size="xl"
        bold
        title={loading ? "Saving" : " Save "}
        onClick={saveUser}
      />
    </div>
  );
}

export default UserForm;
