import { useParams } from "react-router-dom";
import Page from "components/Page";
import CharlesButton from "components/charles/base";
import { Fragment, useState } from "react";
import moment from "moment";

const LumpSumSupplierView = ({ plans, suppliers }) => {
  const { supplierId } = useParams();

  const supplier = suppliers.find((i) => i.id === parseInt(supplierId));

  let plansFromSupplierIds = [];
  supplier.products.forEach((product) => {
    product.plans.forEach((plan) => {
      if (!plansFromSupplierIds.includes(plan.id)) {
        plansFromSupplierIds.push(plan.id);
      }
    });
  });
  const computedPlans = plans.map((plan) => ({
    ...plan,
    hasProductForSupplier: plansFromSupplierIds.includes(parseInt(plan.id)),
  }));
  const supplierPlans = computedPlans.filter((i) => i.hasProductForSupplier);

  const [selectedPlanIds, setSelectedPlanIds] = useState(
    supplierPlans.map((i) => i.id),
  );

  const products = supplier.products
    .sort((a, b) => b.to_produce - a.to_produce)
    .map((i) => ({
      ...i,
      plans: i.plans.map((j) => {
        const planData = computedPlans.find((k) => parseInt(k.id) === j.id);
        return { ...j, ...planData };
      }),
    }));

  console.log("products", products);

  function toggleSelect() {
    if (selectedPlanIds.length === 0) {
      setSelectedPlanIds(supplierPlans.map((i) => i.id));
    } else {
      setSelectedPlanIds([]);
    }
  }

  return (
    <Page title={supplier.name} backTo="./.." className="h-screen">
      <div className="p-6 grid grid-cols-12 gap-6 flex-1 overflow-auto items-start">
        <div className="card p-0 col-span-9 overflow-auto max-h-full">
          <table>
            <thead>
              <tr className="whitespace-nowrap sticky top-0 z-20 bg-gray-50">
                <th className="px-4 py-3">Product</th>
                <th className="px-4 py-3 text-right">QTY</th>
                <th className="px-4 py-3 text-right">To Produce</th>
              </tr>
            </thead>
            <tbody>
              {products.map((i, index) => (
                <Fragment key={index}>
                  <tr className="border-b dark:border-gray-700 font-semibold">
                    <td className="w-full px-4">
                      #{index + 1} [{i.number}] {i.name}
                    </td>
                    <td className="text-right px-4">{i.qty}</td>
                    <td className="text-right px-4">{i.to_produce}</td>
                  </tr>
                  {i.plans.map((plan) => (
                    <tr
                      key={plan.id}
                      className="border-b dark:border-gray-700 text-xs opacity-70"
                    >
                      <td className="px-4">
                        <div className="flex justify-between items-center">
                          <div>
                            - {plan.name}{" "}
                            {plan.odoo_object_name ? (
                              <span className="text-purple-600">
                                ({plan.odoo_object_name})
                              </span>
                            ) : null}
                          </div>
                          <div>
                            {moment(plan.readyDate).format("YYYY-MM-DD")}
                          </div>
                        </div>
                      </td>
                      <td className="text-right px-4">{plan.qty}</td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
          <div className="text-xs p-4 sticky bottom-0 bg-gray-50 z-20 text-gray-600">
            <p>QTY is the total QTY of the product in the plans.</p>
            <p className=" text-orange-600">
              Note that product with BOM should not be in the list and the cost
              for those items need to be checked.
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LumpSumSupplierView;
