import CharlesButton from "components/charles/base";
import { v4 } from "uuid";

const PreviewQBFileView = ({ items, products, prices, confirmHandler }) => {
  const lines = items.map((item) => {
    const product = products.results.find((p) => p.number === item.itemNumber);
    let newItem = {
      ...item,
      product,
      id: v4(),
      qty: item.qty,
      reduceQty: 0,
      totalCbm: 0,
      totalCost: 0,
      salesPrice: 0,
      discountPercentage: 0,
    };
    if (product) {
      const price = prices.find((p) => p.product.id === product.id);
      console.log("price", price);
      if (price) {
        newItem.salesPrice = price.price;
      }
    }
    return newItem;
  });

  console.log("lines", lines);

  return (
    <div>
      <div>Check the items below and make sure they are correct.</div>
      <div className="-mx-2 text-sm">
        <table>
          <thead>
            <tr className=" whitespace-nowrap">
              <th colSpan={2}>Product</th>
              <th className="text-right">Price / Unit</th>
              <th className="text-right">QTY</th>
              <th className="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line, index) => (
              <tr key={index} className="border-y dark:border-gray-700">
                <td>{index + 1}. </td>
                <td className="w-full">
                  <span>{line.itemNumber}</span>
                  {line.product ? null : (
                    <span className=" text-red-600 font-semibold pl-4">
                      This item is not found in wis. Please check the item
                      number.
                    </span>
                  )}
                </td>
                <td className="text-right">{line.salesPrice.toFixed(2)}</td>
                <td className="text-right">{line.qty}</td>
                <td className="text-right">
                  {(line.salesPrice * line.qty).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td></td>
              <td></td>
              <td className="text-right">
                {lines.reduce((acc, line) => acc + line.qty, 0)}
              </td>

              <td className="text-right">
                {lines
                  .reduce((acc, line) => acc + line.salesPrice * line.qty, 0)
                  .toFixed(2)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="mt-8">
        <CharlesButton primary onClick={() => confirmHandler(lines)}>
          Confirm
        </CharlesButton>
      </div>
    </div>
  );
};

export default PreviewQBFileView;
