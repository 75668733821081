import { gql } from "@apollo/client";

export const FETCH_PRODUCTION_CALENDARS = gql`
  query FETCH_PRODUCTION_CALENDARSS {
    productionCalendars {
      id
      name
    }
  }
`;

export const FETCH_PRODUCTION_CALENDAR = gql`
  query FETCH_PRODUCTION_CALENDAR($id: ID!) {
    productionCalendar(id: $id) {
      id
      usages {
        id
        date
      }
    }
  }
`;

export const FETCH_PRODUCTION_CALENDAR_PLANS = gql`
  query ($id: ID!) {
    productionCalendar(id: $id) {
      id
      usages {
        id
        date
        productionPlan {
          id
          name
          state
          joinQueueAt
        }
      }
    }
  }
`;
