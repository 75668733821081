import { useState } from "react";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";
import StockProductSelector from "./StockProductSelector";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { ADD_PRODUCT_TO_STOCK } from "./graphql";

const AddProductToStockView = ({ hide, stock }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [useSupplierStock, setSupplierStock] = useState(true);
  const [addProductToStock, addProductToStockRes] = useMutation(
    ADD_PRODUCT_TO_STOCK,
    {
      variables: {
        stockId: stock.id,
        productId: selectedProduct?.id,
        useSupplierStock,
      },
      onCompleted() {
        Alert("success", "Product is added to stock.");
        hide();
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );

  const modal = useModals();

  const stockProductIds = stock.products.map((product) => product.id);
  const alreadyAdded = stockProductIds.includes(selectedProduct?.id);

  function tryChooseProduct() {
    modal.present({
      title: "Choose a Product",
      children: (
        <StockProductSelector
          onSelect={(product) => {
            setSelectedProduct(product);
            modal.hide();
          }}
        />
      ),
    });
  }

  return (
    <div>
      <div className="space-y-3">
        <div className="flex items-baseline space-x-3">
          <label htmlFor="">Product:</label>
          <div>
            {selectedProduct && (
              <div className="font-semibold">
                [{selectedProduct.number}] {selectedProduct.name}
              </div>
            )}

            <CharlesButton onClick={tryChooseProduct} className="mt-2">
              Choose Product
            </CharlesButton>
          </div>
        </div>

        <div>
          <div className="flex items-center space-x-3">
            <label htmlFor="useSupplierStock" className="cursor-pointer">
              Use Supplier Stock:{" "}
            </label>
            <input
              id="useSupplierStock"
              type="checkbox"
              checked={useSupplierStock}
              onChange={(e) => setSupplierStock(e.target.checked)}
            />
          </div>
        </div>
      </div>

      <hr />

      <div className="flex items-center space-x-4">
        <CharlesButton
          primary
          disabled={!selectedProduct || alreadyAdded}
          onClick={addProductToStock}
          loading={addProductToStockRes.loading}
        >
          Save
        </CharlesButton>
        {alreadyAdded && (
          <div className="text-red-600">Product already added to stock.</div>
        )}
      </div>
    </div>
  );
};

export default AddProductToStockView;
