import { useQuery } from "@apollo/client";
import { FETCH_ALL_PRODUCTS_FOR_STOCK } from "./graphql";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { searchByProp } from "utils/search";

const StockProductSelector = ({ onSelect }) => {
  const { loading, error, data } = useQuery(FETCH_ALL_PRODUCTS_FOR_STOCK);
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner text="Loading data from Odoo..." />;
  if (error) return <Errors error={error} />;

  const res = data.products.results.filter((product) =>
    searchByProp(product, ["number", "name"], searchText),
  );

  return (
    <div>
      <div>
        <SearchBar
          placeholder="Search by item number or name"
          value={searchText}
          onChange={setSearchText}
        />
      </div>
      <div className="mt-2 divide-y">
        {res.map((product) => (
          <div
            key={product.id}
            onClick={() => onSelect(product)}
            className="cursor-pointer hover:text-blue-600 active:text-blue-700 py-2"
          >
            [{product.number}] {product.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StockProductSelector;
