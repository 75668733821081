import { useMutation, useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import moment from "moment";
import { v4 } from "uuid";

import {
  FETCH_ODOO_INTERNAL_TRANSFER,
  FETCH_ODOO_SALES_ORDER_DETAIL,
  UPDATE_PRODUCTION_PLAN_TO_ODOO,
} from "./graphql";

const SyncPlanToOdooView = ({ planId, odooObject }) => {
  if (!odooObject)
    return (
      <div>This plan has no created sales orders nor internal transfers.</div>
    );

  const query =
    odooObject.objectType === "TRANSFER"
      ? FETCH_ODOO_INTERNAL_TRANSFER
      : FETCH_ODOO_SALES_ORDER_DETAIL;

  const { loading, error, data } = useQuery(query, {
    variables: { ids: [odooObject.objectId], planId },
    fetchPolicy: "network-only",
  });
  const [sync, syncRes] = useMutation(UPDATE_PRODUCTION_PLAN_TO_ODOO, {
    variables: {
      planId,
      odooObjectId: odooObject.objectId,
      odooObjectType: odooObject.objectType,
    },
    onCompleted() {
      Alert("success", "Sync Success");
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: [query],
    awaitRefetchQueries: true,
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const odooObjectDetail = data.odooObjectDetail[0];

  console.log(
    "Plan lines",
    [
      ...data.productionPlan.lines,
      ...data.productionPlan.children.flatMap((i) => i.lines),
    ].filter((i) => i.product.number === "121C06"),
  );
  console.log("Odoo lines", odooObjectDetail.lines);

  const planLines = [
    ...data.productionPlan.lines,
    ...data.productionPlan.children.flatMap((i) => i.lines),
  ]
    .reduce((res, i) => {
      const found = res.find((x) => x.product.id === i.product.id);
      if (found)
        return res.map((x) =>
          x.id === found.id
            ? {
                ...x,
                qty: x.qty + i.finalQty,
              }
            : x,
        );
      return [...res, { ...i, id: v4(), qty: i.finalQty }];
    }, [])
    .map((i) => {
      const odoo = odooObjectDetail.lines.find(
        (x) => x.product && x.product.id === i.product.id,
      );
      console.log("odoo", odoo, i);
      const isSynced = odoo && i.qty === odoo.qty;
      return { ...i, odoo, isSynced };
    });

  console.log("planLines", planLines);

  const planProductIds = planLines.map((i) => i.product.id);

  const odooLinesWithNotFoundInPlanLines = odooObjectDetail.lines.filter(
    (i) => !i.product || !planProductIds.includes(i.product.id),
  );

  const isSynced = planLines.every((line) => line.isSynced);

  return (
    <div>
      <div className="text-lg">
        <label className="text-purple-600 pr-2 opacity-100">Odoo: </label>{" "}
        <span className="font-semibold">{odooObjectDetail.name}</span>
      </div>

      <div className="-mx-2 mt-4">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-right text-purple-600">Odoo QTY</th>
              <th className="text-right">Plan QTY</th>
            </tr>
          </thead>
          <tbody>
            {planLines.map((line, planLineIndex) => (
              <tr
                key={planLineIndex}
                className={`border-b dark:border-gray-700
                           ${!line.odoo || line.qty !== line.odoo.qty ? "text-red-600" : ""}`}
              >
                <td>
                  {planLineIndex + 1}. [{line.product.number}]{" "}
                  {line.product.name}
                </td>
                <td className="text-right">{line.odoo ? line.odoo.qty : 0}</td>
                <td className="text-right">{line.qty}</td>
              </tr>
            ))}
            {odooLinesWithNotFoundInPlanLines.map((line, odooLineindex) => (
              <tr key={odooLineindex} className={`border-b text-red-600`}>
                <td>{line.name}</td>
                <td className="text-right"> - </td>
                <td className="text-right">{line.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex space-x-3 mt-6">
        <label htmlFor="">Estimated Ready Date: </label>
        <span>
          {moment(data.productionPlan.finalReadyDate).format("YYYY-MM-DD")}
        </span>
      </div>

      {isSynced ? (
        <div className="mt-8 text-green-600 font-semibold">
          This plan is already Sycned with Odoo.
        </div>
      ) : (
        <>
          <div className="mt-8 text-sm text-orange-600 font-semibold">
            Note: This sync function is still live testing, make sure you double
            check the Sales Order or Internal Transfer in Odoo after sync!! Odoo
            does not support deleting items in a confirmed Sales Order or
            Internal Trasnfer. You should rather reduce the original quantity to
            0.
          </div>

          <div className="mt-8">
            <CharlesButton primary loading={syncRes.loading} onClick={sync}>
              Confirm and Sync
            </CharlesButton>
          </div>
        </>
      )}
    </div>
  );
};

export default SyncPlanToOdooView;
