import React, { useContext } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Accounts from "./accounts";
import Products from "./products/All/Products";
import ProductLines from "./products/All/ProductLines";
import { LeftButtons } from "./products/Header";
import Page from "components/Page";
import ProductForm from "./products/All/ProductForm";
import { AppContext } from "App";
import { MANAGE_EDITOR_PARTIAL } from "utils/permissions";

function ManageIndex() {
  const { hasPermission } = useContext(AppContext);

  if (hasPermission())
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="products" element={<Products />} />
          <Route path="product-lines" element={<ProductLines />} />
        </Route>
        <Route path="products/:id" element={<ProductForm />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="*" element={<Navigate to="products" />} />
      </Routes>
    );

  if (hasPermission(MANAGE_EDITOR_PARTIAL))
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="products" element={<Products />} />
        </Route>
        <Route path="products/:id" element={<ProductForm />} />
        <Route path="accounts" element={<Accounts />} />
        <Route path="*" element={<Navigate to="products" />} />
      </Routes>
    );

  return (
    <div className="flex flex-1 items-center justify-center font-bold text-2xl h-screen text-gray-600">
      Not Allowed.
    </div>
  );
}

const Layout = () => (
  <Page leftButtons={<LeftButtons />} className="lg:h-screen">
    <Outlet />
  </Page>
);

export default ManageIndex;
