import { FileSelector, Input } from "components/Form";
import PlanLine from "./PlanLine";
import CharlesButton from "components/charles/base";
import moment from "moment";
import { v4 } from "uuid";
import { useQuery } from "@apollo/client";
import { FETCH_PRODUCTS_FOR_OS } from "./graphql";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import Excel from "exceljs";
import PreviewQBFileView from "./PreviewQBFileView";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import { RiArrowUpDownLine } from "react-icons/ri";
import { useState } from "react";

const ProductionLines = ({
  originalPlan,
  computedLines,
  setLines,
  onChangeLine,
  onChangeLineProduct,
  removeLine,
  needToRecompute,
  setNeedToRecompute,
  disabled,
  isComputing,
  showBom,
  reduceQty,
  shippingCost,
  setShippingCost,
  orderType,
  currency,
  wisCustomerId,
  productCost,
  totalCbm,
  totalQty,
  totalCost,
  totalPrice,
  prices,
  isLoadingPriceList,
}) => {
  const { loading, error, data } = useQuery(FETCH_PRODUCTS_FOR_OS, {
    skip: originalPlan.state !== "DRAFT",
    variables: { customerId: wisCustomerId },
  });
  const importQBModal = useModals();
  const [sortBy, setSortBy] = useState("PRODUCT");

  const xlsxReader = new FileReader();
  const wb = new Excel.Workbook();
  xlsxReader.onload = function (e) {
    wb.xlsx
      .load(e.target.result)
      .then(handleQbXlsx)
      .catch((error) => {
        Alert("error", `Fail to read xlsx file. Error: ${error}`);
      });
  };

  function onSelectQBFile(e) {
    const file = e.target.files[0];
    xlsxReader.readAsArrayBuffer(file);
    e.target.value = null;
  }

  function addProduct() {
    setLines((prev) => [
      ...prev,
      {
        id: v4(),
        product: null,
        qty: 0,
        reduceQty: 0,
        totalCbm: 0,
        totalCost: 0,
        salesPrice: 0,
        discountPercentage: 0,
      },
    ]);
  }

  function handleQbXlsx(wb) {
    let items = [];
    const ws = wb.getWorksheet("Sheet1");

    for (var i = 2; i <= ws.rowCount; i++) {
      const row = ws.getRow(i);
      if (row.getCell("F").value) {
        const item = {
          qty: row.getCell("E").value,
          itemNumber: row.getCell("F").value,
        };
        items.push(item);
      }
    }

    importQBModal.present({
      title: "Import from Quickbooks",
      maxWidth: "max-w-5xl",
      children: (
        <PreviewQBFileView
          items={items}
          products={data.products}
          prices={prices}
          confirmHandler={confirmHandler}
        />
      ),
    });

    function confirmHandler(newLines) {
      setNeedToRecompute(true);
      setLines((prev) => [...prev, ...newLines]);
      importQBModal.hide();
    }
  }

  if (loading || isLoadingPriceList)
    return (
      <div className="p-20">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  const duplicatedProducts = computedLines.filter(
    (line, index, self) =>
      line.product &&
      index !== self.findIndex((t) => t.product.id === line.product.id),
  );

  return (
    <table className="-mx-2">
      <thead>
        <tr className="whitespace-nowrap">
          <th>
            <div className="flex items-center space-x-1">
              <span>Product</span>
              <CharlesButton onClick={() => setSortBy("PRODUCT")}>
                <RiArrowUpDownLine />
              </CharlesButton>
            </div>
          </th>
          <th className="text-right w-12">QTY / Carton</th>
          <th className="text-right w-20">QTY</th>
          <th className="text-right w-20">CBM</th>
          <th className="text-right w-28">
            <div className="flex items-center justify-end space-x-1">
              <CharlesButton onClick={() => setSortBy("READY_DATE")}>
                <RiArrowUpDownLine />
              </CharlesButton>
              <span>Ready Date</span>
            </div>
          </th>
          <th className="text-right w-20">Unit Cost</th>
          <th className="text-right w-28">Total Cost</th>
          {orderType === "FOB_ORDER" ? (
            <>
              <th className="text-right w-20">Unit Price</th>
              <th className="text-right w-20">Disc %</th>
              <th className="text-right w-20">Total ({currency})</th>
            </>
          ) : null}
        </tr>
      </thead>
      <tbody>
        {computedLines
          .sort((a, b) => {
            if (sortBy === "READY_DATE") {
              return new Date(a.readyDate) - new Date(b.readyDate);
            }
            return a.id - b.id;
          })
          .map((line, index) => (
            <PlanLine
              index={index}
              key={line.id}
              products={
                data
                  ? data.products.results.map((i) => ({
                      ...i,
                      name: `[${i.number}] ${i.name}`,
                    }))
                  : []
              }
              state={originalPlan.state}
              line={line}
              onChangeLine={onChangeLine}
              onChangeLineProduct={onChangeLineProduct}
              removeLine={removeLine}
              setNeedToRecompute={setNeedToRecompute}
              disabled={disabled}
              showSalesPrice={orderType === "FOB_ORDER"}
              isComputing={isComputing}
              showBom={showBom}
            />
          ))}
      </tbody>

      <tbody>
        <tr>
          <td colSpan={6}>
            <div className="flex items-center space-x-4">
              <CharlesButton
                disabled={originalPlan.state !== "DRAFT" || isComputing}
                onClick={addProduct}
              >
                + Add Product
              </CharlesButton>

              <FileSelector
                bold
                disabled={originalPlan.state !== "DRAFT" || isComputing}
                title="Import QB XLSX"
                accept=".xlsx"
                onChange={onSelectQBFile}
              />

              {originalPlan.state === "HANDLED" ? (
                <CharlesButton onClick={reduceQty}>Reduce QTY</CharlesButton>
              ) : null}

              {originalPlan.state === "DRAFT" ? (
                <CharlesButton
                  className="opacity-80"
                  danger
                  onClick={() => {
                    if (window.confirm("Are you sure to clear all products?")) {
                      setLines([]);
                    }
                  }}
                >
                  Clear All Products
                </CharlesButton>
              ) : null}
            </div>

            {wisCustomerId ? null : (
              <div className="mt-4 text-sm">
                Please choose a customer before you can add products.
              </div>
            )}

            <div className="mt-2 opacity-70 italic">
              <p>Cost will be shown once the plan is computed.</p>
              <p>
                When importing QB XLSX file, make sure the file you upload
                contains a sheet named as <b>Sheet1</b>.
              </p>
            </div>
          </td>
          <td className="text-right">{productCost.toFixed(2)}</td>
        </tr>

        {orderType === "INTERNAL_TRANSFER" ? (
          <tr>
            <td colSpan={7} className="text-right py-2">
              <div className="flex space-x-2 justify-end items-center">
                <label>Shipping Cost: </label>
                {disabled ? (
                  <span className="text-base">{shippingCost}</span>
                ) : (
                  <Input
                    disabled={disabled}
                    className="text-right"
                    value={shippingCost}
                    onChange={(e) =>
                      setShippingCost(parseFloat(e.target.value) || 0)
                    }
                  />
                )}
              </div>
            </td>
          </tr>
        ) : null}

        <tr className="text-base whitespace-nowrap">
          <td className="border-y dark:border-gray-700" colSpan={2}>
            Total
          </td>
          <td className="border-y dark:border-gray-700 text-right">
            {totalQty}
          </td>
          <td className="border-y dark:border-gray-700 text-right">
            {totalCbm.toFixed(3)}
          </td>
          <td className="border-y dark:border-gray-700 text-right">
            {isComputing || needToRecompute
              ? "..."
              : originalPlan.readyDate
                ? moment(originalPlan.readyDate).format("YYYY-MM-DD")
                : null}
          </td>
          <td className="text-right border-y dark:border-gray-700"></td>
          <td className="text-right border-y dark:border-gray-700">
            {totalCost.toFixed(2)}
          </td>
          {orderType === "FOB_ORDER" ? (
            <>
              <td className="text-right border-y dark:border-gray-700"></td>
              <td className="text-right border-y dark:border-gray-700"></td>
              <td className="text-right border-y dark:border-gray-700">
                {totalPrice ? totalPrice.toFixed(2) : "0.00"}
              </td>
            </>
          ) : null}
        </tr>

        {duplicatedProducts.length > 0 ? (
          <tr>
            <td className="text-red-600 font-semibold" colSpan={4}>
              Warning: There are duplicated products in the plan. Make sure this
              is intended. Duplicated products:{" "}
              {duplicatedProducts.map((i) => i.product.number).join(", ")}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
};

export default ProductionLines;
