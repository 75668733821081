import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { Input } from "components/Form";
import InputSelect from "components/InputSelect";
import Spinner from "components/Spinner";
import { Button } from "components/base";
import CharlesButton from "components/charles/base";
import { useState } from "react";
import { BsTrash } from "react-icons/bs";

const FETCH_ALL_MATERIALS = gql`
  query FETCH_ALL_MATERIALS {
    allMaterials(withCostOnly: false) {
      id
      name
    }
  }
`;

const MaterialSelectInput = ({
  allMaterials,
  currentMaterial,
  currentMaterialIndex,
  setMaterials,
}) => {
  const [inputMaterial, setInputMaterial] = useState(currentMaterial.name);

  return (
    <InputSelect
      options={allMaterials.map((material) => ({
        name: material.name,
        material,
      }))}
      onSelect={(selectedOption) => {
        setInputMaterial(selectedOption.name);

        setMaterials((prev) =>
          prev.map((m, index) => {
            if (index === currentMaterialIndex) {
              return {
                ...m,
                name: selectedOption.name,
              };
            }
            return m;
          }),
        );
      }}
      onChange={setInputMaterial}
      value={inputMaterial}
      placeholder="Search a material"
      className="w-full"
    />
  );
};

const MaterialsInput = ({ className = "", materials, setMaterials }) => {
  // console.log("materials", materials);
  const { loading, error, data } = useQuery(FETCH_ALL_MATERIALS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className={className}>
      {materials.map((m, index) => (
        <div key={index} className="flex mb-2 space-x-4 items-center">
          <MaterialSelectInput
            allMaterials={data.allMaterials}
            currentMaterial={m}
            currentMaterialIndex={index}
            setMaterials={setMaterials}
          />

          <div className="flex items-center space-x-4">
            <span>&times; </span>
            <Input
              placeholder="Percentage"
              className="text-right w-20"
              value={m.percentage}
              onChange={(e) =>
                setMaterials((prev) =>
                  prev.map((j, jIndex) => {
                    if (index === jIndex)
                      return {
                        ...j,
                        percentage: e.target.value,
                      };
                    return j;
                  }),
                )
              }
            />
            <span>%</span>
          </div>

          <div>
            <CharlesButton
              danger
              onClick={() =>
                setMaterials((prev) =>
                  prev.filter((_, prevIndex) => prevIndex !== index),
                )
              }
            >
              <BsTrash />
            </CharlesButton>
          </div>
        </div>
      ))}

      <div className="pb-2">
        <Button
          title="+ add material"
          onClick={() =>
            setMaterials((prev) => [...prev, { name: "", percentage: "" }])
          }
        />
      </div>
    </div>
  );
};

export default MaterialsInput;
